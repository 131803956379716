:root {
  --primary-color: rgb(245, 199, 71);
  --secondary-color: rgb(29, 30, 77);
  --neutral-color: rgb(181, 181, 207);
}

* {
  box-sizing: border-box;
  margin: 0;
}

body {
  background-color: var(--secondary-color);
  color: white;
  font-family: Arial, Helvetica, sans-serif;
}

section {
  display: flex;
  background-color: var(--neutral-color);
  color: var(--secondary-color);
  margin: 0 auto;
  max-width: 1600px;
  border-right: 10px ridge var(--secondary-color);
  border-left: 10px groove var(--secondary-color);
  padding: 60px;
}

#logo {
  margin: 0 auto 10px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  letter-spacing: 0.3rem;
}

#logo .icon {
  display: inline;
  padding-right: 0.3rem;
  color: rgb(255, 196, 0);
}

#logo:hover {
  cursor: pointer;
}

#updates {
  text-align: left;
  padding: 0 40px;
  width: 100%;
}

#updates h1 {
  font-size: xx-large;
}

.block {
  display: block;
}

.flex {
  display: flex;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-column {
  flex-direction: column;
}

.flex-center {
  align-items: center;
  justify-content: center;
}

.box-shadow {
  box-shadow: 0 2px 5px rgba(0,0,0,0.2);
}

.category-card {
  background-color: var(--secondary-color);
  color: white;
  width: 400px;
  height: 200px;
  font-size: x-large;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px;
  border-radius: 5px;
  border: none;
  transition: 0.8s;
}

.category-card:hover {
  cursor: pointer;
  background-color: var(--primary-color);
  color: var(--secondary-color);
}

.product-card {
  background-color: white;
  color: var(--secondary-color);
  margin: 10px;
  padding: 10px;
  border-radius: 5px;
}

.product-card:hover {
  cursor: pointer;
  filter: brightness(0.95);
}

.update-entry {
  padding: 5px;
  border-bottom: 1px solid white;
}

main {
  text-align: center;
}

.hero {
  position: relative;
  color: white;
  height: 600px;
}

.hero img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.hero button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--primary-color);
  color: var(--secondary-color);
  padding: 20px;
  letter-spacing: 0.1rem;
  border: none;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.2);
  font-size: x-large;
  font-weight: 800;
}

.hero button:hover {
  filter: brightness(0.95);
  cursor: pointer;
}

.breadcrumb {
  list-style: none;
  padding: 5px;
  border-radius: 5px;
  margin-bottom: 5px;
  background-color: var(--secondary-color);
  width: max-content;
}

.breadcrumb a {
  text-decoration: none;
  color: var(--primary-color);
}

.breadcrumb a:hover  {
  filter: brightness(0.95);
}

.breadcrumb li+li:before {
  color: white;
  content: "/\00a0";
  padding: 8px;
}

.current-page {
  color: white;
}

.container {
  background-color: var(--secondary-color);
  color: white;
  width: 400px;
  height: 300px;
  padding: 30px;
  border: 3px solid white;
  border-radius: 3px;
  margin: 10px;
}

.store-info h3 {
  margin-bottom: 10px;
}

.store-info p {
  display: flex;
  justify-content: space-between;
}

.icon-button {
  background: none;
  border: none;
  color: white;
  font-size: large;
  border-radius: 5px;
  padding: 5px 10px;
  margin: 5px;
}

.icon-button:hover {
  cursor:pointer;
  background-color: rgba(0,0,0,0.2);
}


.hidden {
  visibility: hidden;
}

#scrim {
  visibility: hidden;
  position: fixed;
  top:0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 5;
  background-color: rgba(0,0,0,0.4);
}

/* NAVBAR */

#navbar {
  display: flex;
  align-items: center;
  background-color: var(--secondary-color);
  color: white;
  padding: 10px;
  height: 80px;
  box-shadow: 0px 4px 5px rgba(0,0,0,0.2);
}

#navbar h1 {
  padding: 10px;
}

#navbar ul {
  list-style-type: none;
  text-decoration: none;
  padding:0;
}

#navbar a {
  display: block;
  color: inherit;
  text-decoration: none;
  font-size: large;
  padding: 15px 10px;
}

#navbar li:hover {
  background-color: var(--primary-color);
}

#hamburger-menu {
  z-index: 10;
  visibility:hidden;
  position:fixed;
  margin-left: -10px;
  top:0;
  height: 100vh;
  width: 200px;
  background-color: var(--neutral-color);
  color: var(--secondary-color);
}

#close-block {
  display: flex;
  align-items: center;
  padding: 10px;
  height: 80px;
}

#close-button {
  color: var(--secondary-color);
}

@media (max-width:800px) {
  section {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
  }

  .container {
    width: 90%;
  }

  #category-card-container {
    flex-direction: column;
  }
  
  .category-card {
    width: 300px;
  }
}